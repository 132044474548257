export default {
  // api请求地址
  baseUrl: 'https://www.pinkuscm.com/',

  // 图片域名
  imgDomain: 'https://pk.51weizhan.com/',

  // 腾讯地图key
  mpKey: '',

  // 客服
  webSocket: '',

  // api安全
  apiSecurity: '',

  //本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
  pingInterval: 1500,

  // 公钥
  publicKey: ``,
}
